<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      dark
      src="@/assets/top.jpg"

    >

      <template v-slot:img="{ props }">
        <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.3), rgba(128,208,199,.3)"
        ></v-img>
      </template>

      <v-btn
          href="/"
          fab
          text
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <div class="d-flex d-sm-none align-center">
        МБУК «TKK «ДK»
      </div>

      <div class="d-none d-sm-flex align-center">
        МБУК г.о. Новокуйбышевск Самарской области «Театрально концертный комплекс «Дворец культуры»
      </div>

      <v-spacer></v-spacer>
      <div v-if="$store.getters.isAuthenticated">
        <v-tooltip v-for="(item,index) in links" :key="index" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :href="item.link"
                :target="item.outer ? '_blank':'_self'"
                text
            >
              <v-icon>{{item.icon}}</v-icon>
            </v-btn>
          </template>
          <span class="mr-2">{{ item.text }}</span>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                href="http://tkk-dk.ru/"
                target="_blank"
                text
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span class="mr-2">Сайт</span>
        </v-tooltip>
      </div>


    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    links:[
      {text:'Отчет по оплатам',outer:false,link:'/report',icon:'mdi-chart-bar'},
      {text:'Планировщик цен',outer:false,link:'/prices',icon:'mdi-cash-clock'},
      {text:'Оплата на кассе',outer:false,link:'/pay',icon:'mdi-cash-register'},
      {text:'Сайт',outer:true,link:'http://tkk-dk.ru/',icon:'mdi-open-in-new'}
    ],
    //
  }),
};
</script>
