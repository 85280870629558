var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"black","dark":"","src":require("@/assets/top.jpg")},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(19,84,122,.3), rgba(128,208,199,.3)"}},'v-img',props,false))]}}])},[_c('v-btn',{attrs:{"href":"/","fab":"","text":""}},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('div',{staticClass:"d-flex d-sm-none align-center"},[_vm._v(" МБУК «TKK «ДK» ")]),_c('div',{staticClass:"d-none d-sm-flex align-center"},[_vm._v(" МБУК г.о. Новокуйбышевск Самарской области «Театрально концертный комплекс «Дворец культуры» ")]),_c('v-spacer'),(_vm.$store.getters.isAuthenticated)?_c('div',_vm._l((_vm.links),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":item.link,"target":item.outer ? '_blank':'_self',"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.text))])])}),1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":"http://tkk-dk.ru/","target":"_blank","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',{staticClass:"mr-2"},[_vm._v("Сайт")])])],1)],1),_c('v-main',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }