import Vue from 'vue'
import Vuex from 'vuex'

import user from "./modules/user";
import API_connector_dk from '@/utils/connector_dk';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api_dk: new API_connector_dk(),
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user
  }
})
