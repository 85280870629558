
const state = {

    status:         "",

    user:{
        token: localStorage.getItem("user-token") || "",
    }

};

const getters = {

    isAuthenticated:  state => !!state.user.token
};

const actions = {

};

const mutations = {

    authGood:(state, token) =>{

        console.log('authGood');
        localStorage.setItem("user-token", token);
        state.user.token = token;

    },

    authReset:(state) =>{

        console.log('auth reset');
        localStorage.setItem("user-token", "");
        state.user.token = "";


    }

};

export default {
    state,
    getters,
    actions,
    mutations
};
