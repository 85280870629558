'use strict';

//console.log(process.env.VUE_APP_DEV);

export default class API_connector {
    static public_inner_token = 'lkifdjffd09IUJDLF';

    static api_base_url = 'https://api.dk.zakaz.cloud';

    static api_base_dev_url = 'http://dev.api.dk.zakaz.cloud';

    static selectel_self_path = 'https://202702.selcdn.ru/zakaz/d/G0934A7F15';

    static aaa = process.env.VUE_APP_DEV;

    //static api_url = process.env.VUE_APP_DEV ? API_connector.api_base_dev_url : API_connector.api_base_url;
    static api_url = API_connector.api_base_url;

    static token = localStorage.getItem('user-token') || '';



    async post(url, data, json = true, isPublicQuery = false) {

        let headers = {
            Authorization: 'Bearer ' + API_connector.token,
            'Content-Type': 'application/json',
        };
        // общий запрос, не от какого-либо театра, без токена
        if (isPublicQuery) {
            data.global.key = API_connector.public_inner_token;
            headers = {
                'Content-Type': 'application/json',
            };
        }
        let jj = JSON.stringify(data);
        let r = await fetch(API_connector.api_url + url, {
            method: 'POST',
            body: jj,
            headers: headers,
        });
        if (json) {
            return r.json();
        } else {
            return r.text();
        }
    }

    async get(url, json = true) {

        // console.log(API_connector.aaa);
        // console.log(API_connector.api_url);

        let tk = localStorage.getItem('user-token');
        let r = await fetch(API_connector.api_url + url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + tk,
                'Content-Type': 'application/json',
            },
        });
        if (json) {
            return r.json();
        } else {
            return r.text();
        }
    }


    async getJSON(url, json = true) {

        let r = await fetch(API_connector.selectel_self_path + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (json) {
            return r.json();
        } else {
            return r.text();
        }
    }

    async Login(post_data) {
        let t = await this.post('/auth', post_data);
        return t;
    }

    async LoadTeam() {
        let t = await this.get('/teams');
        return t;
    }

    async FindReportByPeriod(post_data) {
        let t = await this.post('/payment_period', post_data);
        return t;
    }

    async FindReportByMonth(post_data) {
        let t = await this.post('/payment_month', post_data);
        return t;
    }

//Названия API под вопросом
    async AddQuery(post_data) {
        let t = await this.post('/query', post_data);
        return t;
    }

//good, но нужно протестировать на реальных данных
    async AddPayment(post_data) {
        let t = await this.post('/payment', post_data);
        return t;
    }

//good, но нужно протестировать на реальных данных
    async LoadSmartLink(smartlink) {
        let t = await this.get('/smartlink/' + smartlink);
        return t;
    }

    async LoadTeamData(team_id) {
        let t = await this.get('/team/' + team_id);
        return t;
    }

    async FindPriceMounth(mounth){
        let t = await this.get('/teams/' + mounth);
        return t;
    }
    async FindTeamPriceDay(team,day){
        let t = await this.get('/team/' + team + '/' + day);
        return t;
    }
    async AddStartPrice(data){
        const post_data = {
            global: {
                key: 'no need',
                action: 'add',
                module: 'price',
                comment: 'global пока не используется вообще'
            },
            data: {
                base: data
            }
        }
        let t = await this.post('/price', post_data);
        return t;
    }
}
